import React, { useState, useEffect } from "react";
import { Card, Form, Alert, Button } from "react-bootstrap";
import { FaTimes, FaCaretDown, FaCaretUp } from "react-icons/fa";
import { useSelector } from "react-redux";
import { store } from "../redux-store";

import { callAPI, apiUri } from "./api";
import { isUserSignedIn, signOut, AccountModal } from "./accountManagement";
import Modal from "react-bootstrap/Modal";
import ModalBody from "react-bootstrap/ModalBody";
import ModalHeader from "react-bootstrap/ModalHeader";
import ModalTitle from "react-bootstrap/ModalTitle";
import ModalFooter from "react-bootstrap/ModalFooter";

export const Comments = ({ commentFor, commentForId, commentForName }) => {
  const userData = useSelector((state) => state.userData);

  const commentLoadLimit = 10;

  const ReplyToComment = ({ replyToRec }) => {
    const [isReplyModalOpen, setIsReplyModalOpen] = React.useState(false);
    const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false);

    const clickReply = () => {
      setIsReplyModalOpen(true);
    };

    const userReviewClose = () => {
      setIsReplyModalOpen(false);
    };

    const onCloseAccountModal = () => {
      //console.log('onCloseAccountModal');
      setIsAccountModalOpen(false);
    };

    const onSignInAccountModal = () => {
      //console.log('onSignInAccountModal');
      setIsAccountModalOpen(false);
    };

    const onSignUpAccountModal = () => {
      //console.log('onSignUpAccountModal');
      setIsAccountModalOpen(false);
    };

    const onForgotPasswordAccountModal = () => {
      //console.log('onForgotPasswordAccountModal');
      setIsAccountModalOpen(false);
    };

    const clickSignInReply = () => {
      setIsReplyModalOpen(false);
      setIsAccountModalOpen(true);
    };

    const UserReviewModal = () => {
      return (
        <>
          <div className="d-flex justify-content-end">
            <Button onClick={userReviewClose} variant="link">
              <FaTimes />
            </Button>
          </div>
          <ModalHeader>
            <ModalTitle>Replying to user {replyToRec.display_name}</ModalTitle>
          </ModalHeader>
          <ModalBody>
            <Card.Title>User comment</Card.Title>
            <div className="mt-1">{replyToRec.comment_text}</div>
          </ModalBody>
          <ModalFooter className="justify-content-start">
            <UserReviewForm
              isReply={true}
              onClickSignIn={clickSignInReply}
              onClickCancel={userReviewClose}
              captchaRequestToken="test"
              replyToCommentId={replyToRec.id}
            />
          </ModalFooter>
        </>
      );
    };

    const ReviewModal = () => {
      return (
        <Modal show={isReplyModalOpen || isAccountModalOpen}>
          {isReplyModalOpen ? (
            <UserReviewModal />
          ) : (
            <AccountModal
              onClose={onCloseAccountModal}
              onSignIn={onSignInAccountModal}
              onSignUp={onSignUpAccountModal}
              onForgotPassword={onForgotPasswordAccountModal}
              initialContentType="signin"
            />
          )}
        </Modal>
      );
    };

    return (
      <>
        <ReviewModal />
        <div>
          <Button variant="link" className="p-0 local-underline-link" onClick={clickReply}>
            REPLY
          </Button>
        </div>
      </>
    );
  };

  const CommentRow = ({ index, rec }) => {
    var stars = <></>;
    var x = 0;
    for (x = 0; x < rec.rating; x++) {
      stars = (
        <>
          {stars}
          <div className="rating-star-small"></div>
        </>
      );
    }
    for (x = rec.rating; x < 5; x++) {
      stars = (
        <>
          {stars}
          <div className="rating-empty-star-small"></div>
        </>
      );
    }

    //var options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    var options = { year: "numeric", month: "short", day: "numeric" };
    const create_date = new Date(
      rec.created_date.substring(0, 4),
      parseInt(rec.created_date.substring(5, 7)) - 1,
      rec.created_date.substring(8, 10)
    );
    const formatted_date = create_date.toLocaleDateString("en-US", options);

    return (
      <div className="mb-4">
        <div className="d-flex">
          <div className="d-flex">
            <div className="mb-1 me-2 recipe-user-review-stars">{stars}</div>
            <div className="me-3">
              <b>{rec.display_name}</b>
            </div>
          </div>
          <div>{formatted_date}</div>
        </div>
        <div className="xxmb-1">{rec.comment_text}</div>
        {rec.level_number > 7 ? <></> : <ReplyToComment replyToRec={rec} />}
        <ShowComments parentId={rec.id} childrenCount={rec.children_count} autoQuery={false} />
      </div>
    );
  };

  const ShowComments = ({ parentId, childrenCount = 0, autoQuery }) => {
    const [initialQueryDone, setInitialQueryDone] = useState(false);
    const [showReplies, setShowReplies] = useState(false);
    const [isMoreReplies, setIsMoreReplies] = useState(false);
    const [commentList, setCommentList] = useState([]);

    useEffect(() => {
      const queryCommentsInitial = async () => {
        console.log("in ShowReplies queryComments");
        var url = "/comments/search";
        var params = "?comment_for=" + commentFor + "&include_null=false&limit=" + commentLoadLimit + "&skip=0";
        if (parentId === 0) {
          params = params + "&level_number=1";
        } else {
          params = params + "&parent_id=" + parentId;
        }
        params = params + "&comment_for_id=" + commentForId;

        var newCommentList = [];
        var result = await callAPI(url + params, "GET", null, userData, store, false);
        if (result.status === "success") {
          console.log("returned from api");
          result.data.records.forEach((rec) => {
            rec.show_replies = false;
            newCommentList.push(rec);
          });
          setCommentList(JSON.parse(JSON.stringify(newCommentList)));
          setIsMoreReplies(newCommentList.length < result.data.count);
        } else {
          console.error("Error:", result.message);
        }
      };

      console.log("in useEffect - autoQuery=" + autoQuery + " initialQueryDone=" + initialQueryDone);
      if (autoQuery && !initialQueryDone) {
        setInitialQueryDone(true);
        queryCommentsInitial();
      }
    }, [autoQuery, parentId, initialQueryDone]);

    const queryCommentsLoadMore = async (skip) => {
      console.log("in ShowReplies queryCommentsLoadMore");
      var url = "/comments/search";
      var params = "?comment_for=" + commentFor + "&include_null=false&limit=" + commentLoadLimit + "&skip=" + skip;
      if (parentId === 0) {
        params = params + "&level_number=1";
      } else {
        params = params + "&parent_id=" + parentId;
      }
      params = params + "&comment_for_id=" + commentForId;

      var newCommentList = JSON.parse(JSON.stringify(commentList));
      var result = await callAPI(url + params, "GET", null, userData, store, false);
      if (result.status === "success") {
        console.log("returned from api");
        result.data.records.forEach((rec) => {
          rec.show_replies = false;
          newCommentList.push(rec);
        });
        setCommentList(JSON.parse(JSON.stringify(newCommentList)));
        setIsMoreReplies(newCommentList.length < result.data.count);
      } else {
        console.error("Error:", result.message);
      }
    };

    const loadMoreReplies = () => {
      console.log("loadMoreReplies");
      queryCommentsLoadMore(commentList.length);
    };

    const clickShowReplies = () => {
      if (showReplies) {
        setShowReplies(false);
      } else {
        if (commentList.length === 0) {
          queryCommentsLoadMore(0);
        }
        setShowReplies(true);
      }
    };

    return (
      <>
        {parentId === 0 && commentList.length === 0 ? <b>No review comments yet. You can be the first!</b> : <></>}

        {parentId !== 0 && childrenCount > 0 ? (
          <div>
            <Button variant="link" onClick={clickShowReplies} className="p-0 recipe-comment-replies-link">
              {showReplies ? <FaCaretUp /> : <FaCaretDown />} {childrenCount}{" "}
              {childrenCount === 1 ? "REPLY" : "REPLIES"}
            </Button>
          </div>
        ) : (
          <></>
        )}

        {commentList.length !== 0 && (showReplies || autoQuery) ? (
          <>
            <div className={(parentId === 0 ? "" : "recipe-review-indent ") + "mb-1"}>
              {commentList.map((result, index) => (
                <CommentRow key={result.id} index={index} rec={result} />
              ))}
            </div>
            {isMoreReplies ? (
              <div className={(parentId === 0 ? "" : "recipe-review-indent ") + "mb-1"}>
                <Button variant="link" className="local-underline-link" onClick={loadMoreReplies}>
                  LOAD MORE
                </Button>
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const UserReviewForm = ({ isReply, onClickSignIn, onClickCancel, replyToCommentId }) => {
    const [reviewBannerMessage, setReviewBannerMessage] = useState("");
    const [reviewComment, setReviewComment] = useState("");
    const [reviewRating, setReviewRating] = useState(0);
    const [newRating, setNewRating] = useState(0);
    const [submitStatus, setSubmitStatus] = useState("NEW");
    const [captchaRequestToken, setCaptchaRequestToken] = useState("");

    //console.log('UserReviewForm isUserSignedIn(userData)='+isUserSignedIn(userData));

    const clickSignOut = () => {
      signOut(() => {});
    };

    const ratingClick = (value) => {
      setReviewRating(value);
      setNewRating(value);
      //console.log('set ReviewRating='+value);
    };
    const mouseEnter = (value) => {
      setReviewRating(value);
      setNewRating(value);
      //console.log('set mouseEnter='+value);
    };
    //const mouseLeave = () => {
    //  setNewRating(reviewRating);
      //console.log('set mouseLeave='+reviewRating);
    //};

    const RatingInput = () => {
      var stars = <></>;

      for (let x = 1; x <= 5; x++) {
        stars = (
          <>
            {stars}
            <div
              className={newRating < x ? "rating-empty-star" : "rating-star"}
              onMouseEnter={() => mouseEnter(x)}
              //onMouseLeave={() => mouseLeave(x)}
              onClick={() => ratingClick(x)}
              value={x}
              role="button"
              tabIndex={0}
              onKeyPress={() => ratingClick(x)}
            >
              &nbsp;
            </div>
          </>
        );
      }
      return stars;
    };

    const getCaptchaRequestToken = async () => {
      //console.log('getting captcha token');
      var result = await callAPI("/captcha/requests/", "POST", null, userData, store, false);
      if (result.status === "success") {
        //console.log('got token='+result.data.captcha_request_token);
        setCaptchaRequestToken(result.data.captcha_request_token);
        setSubmitStatus("CAPTCHA");
      } else {
        if (result.apiStatus === 400) {
          setReviewBannerMessage(result.data.detail);
        } else {
          console.error("Error:", result.message);
          setReviewBannerMessage("Error: " + result.message);
        }
      }
    };

    const submitReview = async (event) => {
      event.preventDefault();
      var review_comment = "";
      var captcha_answer = "";
      var review_form = undefined;
      var captcha_form = undefined;
      if (submitStatus === "NEW") {
        review_form = document.getElementById("submit-review-form");
        review_comment = review_form.review_comment.value;
        setReviewComment(review_comment);
        if (review_comment === "" && reviewRating === 0) {
          setReviewBannerMessage("Please choose your rating and/or enter your review. Thanks!");
          return;
        } else {
          setReviewBannerMessage("");
        }
      }

      if (submitStatus === "CAPTCHA") {
        captcha_form = document.getElementById("submit-review-captcha-form");
        captcha_answer = captcha_form.captcha_answer.value;
        review_comment = reviewComment;
        if (captcha_answer === "") {
          setReviewBannerMessage("Please answer the question below to proceed. Thanks!");
          return;
        }
      }

      //console.log('isUserSignedIn='+isUserSignedIn(userData))
      //console.log('captchaRequestToken='+captchaRequestToken)
      if (!isUserSignedIn(userData)) {
        if (captchaRequestToken === "") {
          getCaptchaRequestToken();
          return;
        }
      }

      //console.log('review_comment='+review_comment+ ' reviewRating='+reviewRating);

      let data = {
        parent_id: replyToCommentId,
        comment_for_name: commentForName,
        new_comment_text: review_comment,
        rating: parseInt(reviewRating),
        captcha_request_token: captchaRequestToken,
        captcha_answer: captcha_answer,
      };

      var result = await callAPI(
        "/comments/" + commentFor + "/" + commentForId,
        "POST",
        data,
        userData,
        store,
        isUserSignedIn(userData)
      );
      if (result.status === "success") {
        //console.log('success')
        setSubmitStatus("DONE");
      } else {
        if (result.apiStatus === 400) {
          if (
            result.data.detail === "CAPTCHA_REQUEST_NOT_EXISTS" ||
            result.data.detail === "CAPTCHA_REQUEST_EXPIRED" ||
            result.data.detail === "CAPTCHA_REQUEST_MAX_USES"
          ) {
            getCaptchaRequestToken();
          } else if (result.data.detail === "CAPTCHA_REQUEST_INVALID_ANSWER") {
            if (submitStatus === "CAPTCHA") {
              setReviewBannerMessage("Incorrect answer. Please try again!");
              if (captcha_form !== undefined) {
                captcha_form.captcha_answer.value = "";
              }
            }
            getCaptchaRequestToken();
          } else if (result.data.detail === "COMMENT_QUOTA_EXCEEDED") {
            setReviewBannerMessage("Sorry, system quota exceeded. Please try again later.");
          } else {
            setReviewBannerMessage(result.data.detail);
          }
        } else {
          console.error("Error:", result.message);
          setReviewBannerMessage("Error: " + result.message);
        }
      }
    };

    return (
      <>
        {submitStatus === "NEW" ? (
          <>
            <Card.Title>Leave a Review</Card.Title>
            {reviewBannerMessage === "" ? (
              <></>
            ) : (
              <Alert className="ms-2 me-2 mt-2" variant="warning">
                {reviewBannerMessage}
              </Alert>
            )}
            <Form noValidate onSubmit={submitReview} id="submit-review-form" autoComplete="off">
              <div className="mb-3">
                {isUserSignedIn(userData) ? (
                  <ul>
                    <li>
                      <div>
                        Currently posting as &quot;{userData.userDisplayName}
                        &quot; or{" "}
                        <Button variant="link" className="local-underline-link p-0" onClick={clickSignOut}>
                          Sign Out
                        </Button>
                      </div>
                    </li>
                  </ul>
                ) : (
                  <>
                    <ul>
                      <li>
                        <div>
                          Currently posting as &quot;Anonymous&quot; or{" "}
                          <Button variant="link" className="local-underline-link p-0" onClick={onClickSignIn}>
                            Sign In
                          </Button>
                        </div>
                      </li>
                    </ul>
                  </>
                )}
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="review_rating" className="form-label">
                  Your Rating - Please leave a star rating!
                </Form.Label>
                <br />
                <RatingInput />
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="review_comment" className="form-label">
                  Your Review - This could be a question, comment, or tips. Thanks for being kind!
                </Form.Label>
                <Form.Control type="text" as="textarea" className="form-control" id="review_comment" rows={4} />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit Review
              </button>
              {isReply ? (
                <Button className="btn btn-secondary ms-3" onClick={onClickCancel}>
                  Cancel
                </Button>
              ) : (
                <></>
              )}
            </Form>
          </>
        ) : (
          <></>
        )}
        {submitStatus === "CAPTCHA" ? (
          <>
            <Card.Title>Please answer the following question to verify that you are not a robot.</Card.Title>
            {reviewBannerMessage === "" ? (
              <></>
            ) : (
              <Alert className="ms-2 me-2 mt-2" variant="warning">
                {reviewBannerMessage}
              </Alert>
            )}
            <Form noValidate onSubmit={submitReview} id="submit-review-captcha-form" autoComplete="off">
              <div className="mb-3">
                <Form.Label htmlFor="captcha_question" className="form-label">
                  Question
                </Form.Label>
                <br />
                <img src={apiUri + "/captcha/requests/" + captchaRequestToken} alt="captcha question" />
              </div>
              <div className="mb-3">
                <Form.Label htmlFor="captcha_answer" className="form-label">
                  Answer
                </Form.Label>
                <Form.Control type="text" className="form-control" id="captcha_answer" />
              </div>
              <button type="submit" className="btn btn-primary">
                Submit Answer
              </button>
              {isReply ? (
                <Button className="btn btn-secondary ms-3" onClick={onClickCancel}>
                  Cancel
                </Button>
              ) : (
                <></>
              )}
            </Form>
          </>
        ) : (
          <></>
        )}
        {submitStatus === "DONE" ? (
          <>
            <Card.Title>Thank you! Your review has been submitted.</Card.Title>
            <div>Your review will become public once it has been approved. </div>
            {isReply ? (
              <Button className="btn btn-primary mt-5" onClick={onClickCancel}>
                Close
              </Button>
            ) : (
              <></>
            )}
          </>
        ) : (
          <></>
        )}
      </>
    );
  };

  const PostCommentForm = () => {
    const [isAccountModalOpen, setIsAccountModalOpen] = React.useState(false);

    const onCloseAccountModal = () => {
      //console.log('onCloseAccountModal');
      setIsAccountModalOpen(false);
    };

    const onSignInAccountModal = () => {
      //console.log('onSignInAccountModal');
      setIsAccountModalOpen(false);
    };

    const onSignUpAccountModal = () => {
      //console.log('onSignUpAccountModal');
      setIsAccountModalOpen(false);
    };

    const onForgotPasswordAccountModal = () => {
      //console.log('onForgotPasswordAccountModal');
      setIsAccountModalOpen(false);
    };

    const clickSignIn = () => {
      setIsAccountModalOpen(true);
    };

    return (
      <>
        <Modal show={isAccountModalOpen}>
          <AccountModal
            onClose={onCloseAccountModal}
            onSignIn={onSignInAccountModal}
            onSignUp={onSignUpAccountModal}
            onForgotPassword={onForgotPasswordAccountModal}
            initialContentType="signin"
          />
        </Modal>
        <UserReviewForm isReply={false} captchaRequestToken="test" onClickSignIn={clickSignIn} />
      </>
    );
  };

  return (
    <>
      <div id="reviews" className="scroll-anchor">&nbsp;</div>
      <div>
        <span className="recipe-subtitle">
          <strong>REVIEWS</strong>
        </span>
        <Card>
          <Card.Body>
            <PostCommentForm />
          </Card.Body>
        </Card>
        <div className="mt-3">
          <ShowComments parentId={0} autoQuery={true} />
        </div>
      </div>
    </>
  );
};
