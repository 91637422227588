import React from "react";
import { GatsbyImage } from "gatsby-plugin-image";
import parse from "html-react-parser";
import { Row, Container, Col } from "react-bootstrap";

import Seo from "../components/seo";

const RecipePrint = ({ post, featuredImage }) => {
  return (
    <>
      <Seo title={post.title} keywords={[`recipe`]} url={post.uri} />

      <Container id="recipe-print-container">
        <Row>
          <div className="d-flex flex-row justify-content-between pb-2 pe-4">
            <h1 itemProp="headline" className="d-flex flex-shrink-1">
              {parse(post.title)}
            </h1>
          </div>
          <div>
            <b>
              I Love It Camping
              <br />
              https://www.iloveitcamping.com{post.uri}
            </b>
          </div>
        </Row>

        <Row>
          <Col>
            <article className="blog-post" itemScope itemType="http://schema.org/Article">
              <header>
                {/* if we have a featured image for this post let's display it */}
                {featuredImage?.image && (
                  <GatsbyImage
                    image={featuredImage.image}
                    alt={featuredImage.alt}
                    style={{ marginBottom: 10, width: "200px" }}
                  />
                )}
              </header>

              {!!post.content && <section itemProp="articleBody">{parse(post.content)}</section>}

              <footer></footer>
            </article>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default RecipePrint;
